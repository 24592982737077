import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column justify-content-center align-items-center p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error = _resolveComponent("error")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_error, { error: {
        title: 'Du har ikke tilladelse til at se denne side',
        message:
          'Du har ikke de nødvendige rettigheder til at se oplysningerne på denne side. Kontakt din systemadministrator for at få hjælp.',
      } }, null, 8, ["error"])
  ]))
}